<template>
  <b-overlay
    :show="false"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
    class="min-vh-100"
  >
    <div
      id="user-profile"
    >
      <stylist-page-header :header-data="header" />
      <section id="stylist-info">
        <b-row class="mr-0 ml-0">
          <b-col
            lg="12"
            cols="12"
            order="1"
            order-lg="2"
          >
            <b-card
              class="mb-0"
            >
              <h3 class="mb-2">
                Terms of service
              </h3>
              <b-row>
                <b-col
                  md="8"
                  cols="6"
                />
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import StylistPageHeader from '@/components/stylist/stylist-page/StylistPageHeader.vue'
import {
  BCard, BCol,
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BOverlay,
    StylistPageHeader,
  },
  data() {
    return {
      header: {
        avatar: require('@/assets/images/user-uploads/stylist-profile/avatars/avatar-s-2.jpg'),
        businessName: 'Term of service',
        coverImg: require('@/assets/images/user-uploads/stylist-profile/backgrounds/timeline.jpg'),
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';

.border-left-1-gray {
  border-left: 1px solid gray;
}

@media (max-width: 991px) {
  .second-column {
    border-left: none !important;
  }
  .booking-fields-box {
    margin-top: 2rem;
  }
}
</style>
